import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import RedactionRepository from '@/dataroom/infrastructure/repository/RedactionRepository';
import { RpcError } from '@dealroadshow/json-rpc-dispatcher';
import ErrorCodeHelper from '@finsight/error-codes';
import { NotificationManager } from '@/Framework/Notification';
import { messageCodes } from '@/Framework/Message/messages';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { getItemsPayload, Items } from '@/dataroom/application/redaction/helpers/getItemsPayload';

export const useCheckFiles = () => {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const [isFetching, setIsFetching] = useState(false);
  const [canRedact, setCanRedact] = useState(false);
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);

  const checkFilesForRedactByKeyword = async (items: Items) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        items: getItemsPayload(items),
      };

      const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

      const { validation } = await redactionRepository.checkFilesForRedactByKeyword(payload);
      setCanRedact(validation);
    } catch (error) {
      if (error instanceof RpcError) {
        if (error?.getCode() === ErrorCodeHelper.getCodeByName('DATAROOM_REDACTION_SEARCH_BY_KEYWORD_LIMIT_EXCEEDED')) {
          setIsLimitExceeded(true);
          return;
        }
        if (error?.getCode() === ErrorCodeHelper.getCodeByName('DATAROOM_REDACTION_NO_SCANNABLE_FILES_SELECTED')) {
          NotificationManager.error(getMessage(messageCodes.DATAROOM_REDACTION_NO_SCANNABLE_FILES_SELECTED));
          return;
        }
      }
      container.get(DataroomErrorHandler)
        .handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    canRedact,
    setCanRedact,
    isLimitExceeded,
    setIsLimitExceeded,
    checkFilesForRedactByKeyword,
  };
};
