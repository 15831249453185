import { useMemo, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/Framework/Notification';
import TimeZoneRepository from '@/Framework/TimeZone/TimeZoneRepository';
import { ITimezone } from '@/Framework/TimeZone/vo/Timezone';
import { getDefaultTimeZone, getTimeZoneOption } from '@/Framework/TimeZone/helpers';

export const useTimeZone = () => {
  const { container } = useDIContext();
  const timeZoneRepository = container.get<TimeZoneRepository>(TimeZoneRepository);

  const [timeZoneList, setTimeZoneList] = useState<ITimezone[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetched, setIsFetched] = useState(false);

  const timeZoneOptions = useMemo(() => {
    return timeZoneList.map(getTimeZoneOption);
  }, [timeZoneList]);

  const defaultTimeZone = useMemo(() => {
    return getDefaultTimeZone(timeZoneList);
  }, [timeZoneList]);

  const getTimeZoneList = async ({
    shouldHandleError = true,
  }: {
    /**
     * Determines whether the error needs to be handled
     * If not, the error will be thrown
     * By default: true
     */
    shouldHandleError?: boolean,
  } = {}): Promise<ITimezone[]> => {
    if (isFetched) {
      return timeZoneList;
    }

    setIsFetching(true);

    try {
      const timeZoneList = await timeZoneRepository.getList();
      setTimeZoneList(timeZoneList);
      setIsFetched(true);

      return timeZoneList;
    } catch (error) {
      if (shouldHandleError) {
        NotificationManager.error(getErrorMessage(error));
        return [];
      }

      throw error;
    } finally {
      setIsFetching(false);
    }
  };

  return {
    getTimeZoneList,
    defaultTimeZone,
    timeZoneList,
    timeZoneOptions,
    isFetching,
    isFetched,
  };
};
