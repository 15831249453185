import { domain } from '@/Framework/config';
import env from '@/Framework/env/env';
import { IEverсallConfig } from '@/evercall/domain/vo/IEverсallConfig';

const config: IEverсallConfig = {
  name: 'Evercall',
  code: 'evercall',
  abbr: 'EVC',
  supportEmail: env('CREDENTIALS__EVERCALL__SUPPORT__EMAIL'),
  salesEmail: env('CREDENTIALS__EVERCALL__SUPPORT__SALES_EMAIL'),
  hostname: `${ domain.evercallDomain }`,
  supportPhoneNumber: env('CREDENTIALS__EVERCALL__SUPPORT__PHONE'),
  useApiProxy: true,
  ga: {
    trackingId: env('GA__TRACKING_ID_EVERCALL'),
  },
  gtm: {
    trackingId: env('FRONTEND___GOOGLE_TAG_MANAGER__TENANT_EVERCALL__GTM_ID'),
  },
  options: {
    isNewApp: false,
  },
};

export default config;
