import { useState } from 'react';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useDIContext } from '@/Framework/DI/DIContext';
import RedactionRepository from '@/dataroom/infrastructure/repository/RedactionRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { NotificationManager } from '@/Framework/Notification';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';

export default function useApply() {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const applyAllRedactions = async (fileId: number, fileName: string) => (
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      setIsFetching(true);

      const onFinish = () => {
        setIsFetching(false);
        resolve(true);
        NotificationManager.success(getMessage(messageCodes.DATAROOM_APPLY_PENDING_SUCCESS, { fileName }));
      };

      const onError = () => {
        setIsFetching(false);
        reject();
        NotificationManager.error(getMessage(messageCodes.GENERAL_ERROR));
      };

      try {
        const payload = {
          dataroomId: dataroom.id,
          fileId,
          onFinish,
          onError,
        };

        const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

        await redactionRepository.applyAllRedactions(payload);
      } catch (error) {
        setIsFetching(false);
        reject();
        container.get(DataroomErrorHandler)
          .handleError(error);
      }
    }));

  return {
    isFetching,
    applyAllRedactions,
  };
}
